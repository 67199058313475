import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { faFileCertificate } from '@fortawesome/pro-duotone-svg-icons'

class Certifications extends React.Component {
  render() {
    return (
      <MDBRow className="mb-5">
        <MDBCol>
            <div className="bg-white rounded p-5">
              <h3 className="font-alt font-w-700 letter-spacing-1 title-medium title-lg-large pb-3">
              <FontAwesomeIcon icon={faFileCertificate} className="mr-3 va-m ico-color" size="2x" />{this.props.title}
              </h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.description,
                }}
                className="text-medium"
              />
              <a
                href={this.props.document.publicURL}
                className="nav-link btn btn-mdb-color btn-sm-block mt-5"
                target="_blank"
                rel="noopener"
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                {this.props.linktext}
              </a>
            </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default Certifications
