import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import Certifications from '../../components/certifications'
import Img from 'gatsby-image'
import CarouselQuote from '../../components/carouselQuote'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

export default ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <section className="bg-white">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="mt-3 richtext divlink richtext-image"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />{' '}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </section>

      <section className="bg-gray-light">
        <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                  Our certifications
                </h2>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol>
                {post.frontmatter.section.map((certs, index) => {
                  return (
                    <Certifications
                      key={index}
                      title={certs.title}
                      description={certs.description}
                      linktext={certs.linktext}
                      document={certs.document}
                    />
                  )
                })}
              </MDBCol>{' '}
            </MDBRow>
          </MDBContainer>
        </MDBAnimation>
      </section>

      {post.frontmatter.quote && post.frontmatter.quote.length > 0 && (
          <section className="blockback">
            {post.frontmatter.quote.map((quotes, index) => {
              return (
                <CarouselQuote
                  key={index}
                  quotenumber={quotes.quotenumber}
                  strapline={quotes.strapline}
                  author={quotes.author}
                />
              )
            })}
          </section>
        )}

      {post.frontmatter.qlssection.map((qlssections, index) => (
        <section className="bg-light-blue-prods" key={index}>
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pr-5 mb-5">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {qlssections.title}
                  </h2>
                  <div
                    className="mt-3 font-w-400 text-medium"
                    dangerouslySetInnerHTML={{ __html: qlssections.description, }}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow className="mb-5">
                <MDBCol md="3" className="d-none d-sm-block d-md-none d-lg-block">
                  <Img
                    top
                    fixed={qlssections.image.childImageSharp.fixed}
                    /* className="d-none d-sm-block" */
                    alt={qlssections.alttext}
                  />
                </MDBCol>

                <MDBCol md="9">
                  <MDBRow>
                    {qlssections.qlsfeature.map((qlsfeatures, index) => (
                      <MDBCol md="12" className="pb-5 px-lg-0 item-prods" key={index}>
                        <div className="feature-icon">
                          <FontAwesomeIcon
                            icon={['fat', qlsfeatures.icon]}
                            size="3x"
                          />
                        </div>
                        <h4 className="font-alt font-w-700 mt-2 letter-spacing-1 title-small">
                          {qlsfeatures.title}
                        </h4>
                        <div
                          className="font-w-400 text-medium"
                          dangerouslySetInnerHTML={{ __html: qlsfeatures.description }}
                        />
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>
      ))}

    </Layout>
  )
}
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "quality" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        section {
          title
          description
          linktext
          document {
            publicURL
          }
        }
        quote {
          quotenumber
          strapline
          author
        }
        qlssection {
          title
          description
          alttext
          image {
            childImageSharp {
              fixed(width: 166, height: 166, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          qlsfeature {
            title
            icon
            description
          }
        }
      }
      html
    }
  }
`
